// Core React and Router
import React, { useState, useEffect, useRef, Suspense, lazy, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// Third-party Components and Libraries
import { Tab } from '@headlessui/react';

// Icons
import {
    ExclamationCircleIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/24/outline';

// Custom Hooks
import useToggle from '../hooks/useToggle';
import { useAuth, useTheme } from '../auth/AuthContext';
import { useGlobalData } from '../contexts/GlobalDataContext';
import { useComponentVisibility } from '../contexts/ComponentVisibilityContext';
import { useDebouncedDateRange } from '../hooks/useDebouncedDateRange';

// Utils and Config
import { dataTypes } from '../config/dataTypes';
import { prettyDate } from '../utils/dataProcessing';

// Components
import LoadingSpinner from './Layouts/LoadingSpinner';
import LoadingOverlay from './LoadingOverlays/LoadingOverlay';

const AdminNavbar = React.lazy(() => import('./Navigation/AdminNavbar'));
const StatcardHeader = React.lazy(() => import('./Layouts/StatcardHeader'));
const DashboardNavigation = React.lazy(() => import('./Navigation/DashboardNavigation'));
const ErrorBoundary = React.lazy(() => import('./ErrorBoundary'));
const Footer = React.lazy(() => import('./Footer'));
const Snapshot = React.lazy(() => import('./Datasources/Snapshot'));
const LeadGeneration = React.lazy(() => import('./Datasources/LeadGeneration'));

/**
 * Main Dashboard Component
 * 
 * Handles the primary analytics dashboard interface including:
 * - Data fetching and state management
 * - PDF report generation
 * - Dynamic section rendering
 * - Admin access control
 * - Tabbed navigation for multiple data sources
 */
function Dashboard() {
    /* ----------------------------------- */
    /*        Core State & Hooks           */
    /* ----------------------------------- */
    const { token, isAdmin, loading: authLoading } = useAuth();
    const { id } = useParams();
    const location = useLocation();
    const isAdminRoute = location.pathname.includes('/admin/');

    // Single combined loading check at the top
    if (authLoading || (isAdminRoute && !isAdmin)) {
        return null;
    }
    const theme = useTheme();
    const [isAdminNavOpen, setIsAdminNavOpen] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState({});
    const [validDataTypes, setValidDataTypes] = useState([]);
    const { visibleComponents } = useComponentVisibility();

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    const threeMonthsAgo = new Date(yesterday);
    threeMonthsAgo.setMonth(yesterday.getMonth() - 3);
    threeMonthsAgo.setHours(0, 0, 0, 0);

    const {
        startDate,
        endDate,
        debouncedStartDate,
        debouncedEndDate,
        setDateRange
    } = useDebouncedDateRange(threeMonthsAgo, yesterday);

    const isInitialLoad = useRef(true);

    const {
        globalData,
        loadingStates,
        sourcesWithData,
        errorStates,
        accounts,
        previousStartDate,
        previousEndDate,
        fetchAccounts,
        fetchData,
    } = useGlobalData();

    const data = globalData?.current || {};
    const yearOverYearData = globalData?.yearOverYear || {};
    const previousPeriodData = globalData?.previousPeriod || {};
    const customPeriodData = globalData?.customPeriod || {};

    const isDataLoading = useMemo(() => {
        return Object.values(loadingStates).some(state => state === true);
    }, [loadingStates]);

    /* ----------------------------------- */
    /*         Reference Objects           */
    /* ----------------------------------- */
    const sectionRefs = {
        dashboardTop: {
            ref: useRef(null),
            label: 'Dashboard Overview',
            useInPDF: false
        },
        dashboardSnapshotCurrent: {
            ref: useRef(null),
            label: 'Current Period Overview',
            useInPDF: true
        },
        dashboardLeadgen: {
            ref: useRef(null),
            label: 'Contact Sources',
            useInPDF: true
        },
        'ga4': {
            refs: {
                traffic: {
                    ref: useRef(null),
                    label: 'Traffic Overview',
                    useInPDF: true
                },
                charts: {
                    ref: useRef(null),
                    label: 'Traffic Details',
                    useInPDF: true
                }
            },
            label: 'Visibility & Traffic',
            useInPDF: true
        },
        'gbp': {
            refs: {
                overview: {
                    ref: useRef(null),
                    label: 'Google Business Profile',
                    useInPDF: true
                },
            },
            label: 'Google Business Profile',
            useInPDF: true
        },
        'call-tracking': {
            refs: {
                overview: {
                    ref: useRef(null),
                    label: 'Call Tracking',
                    useInPDF: true
                },
            },
            label: 'Call Tracking',
            useInPDF: true
        },
        'reviews': {
            refs: {
                overview: {
                    ref: useRef(null),
                    label: 'Reviews',
                    useInPDF: true
                },
            },
            label: 'Reviews',
            useInPDF: true
        },
    };

    const tabsRef = useRef({});
    const pdfSectionRefs = useRef({});

    /* ----------------------------------- */
    /*      Navigation & Scrolling         */
    /* ----------------------------------- */
    const scrollTabs = (key, direction) => {
        const container = tabsRef.current[key];
        if (container) {
            const scrollAmount = container.offsetWidth;
            const newScrollPosition = container.scrollLeft + direction * scrollAmount;
            container.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
        }
    };

    /* ----------------------------------- */
    /*        Authentication               */
    /* ----------------------------------- */
    
    if (authLoading) {
        return null; // or a loading spinner if you prefer
    }
    
    useEffect(() => {
        if (!authLoading) {  // Only proceed if auth is done loading
            if (isAdminRoute && id) {
                fetchAccounts(id);
            } else {
                fetchAccounts();
            }
        }
    }, [authLoading, isAdminRoute, id, fetchAccounts]);
    
    /* ----------------------------------- */
    /*        Data Type Validation         */
    /* ----------------------------------- */
    const checkDataValidity = (key) => {
        if (!accounts) return false;
        if (!data || typeof data !== 'object') return false;
        if (!data[key]) return false;

        return Object.keys(data[key]).length > 0 &&
            Object.values(data[key]).some(value =>
                value !== null &&
                typeof value === 'object' &&
                Object.keys(value).length > 0
            );
    };

    useEffect(() => {
        // Only run validation when both accounts and `globalData.current` are loaded
        if (!accounts || !data) return;

        const newValidDataTypes = dataTypes.filter(({ key }) => {
            const isValid = checkDataValidity(key); // Ensure this function handles `data` structure correctly
            return isValid;
        });

        setValidDataTypes(newValidDataTypes);
    }, [accounts, data]);


    /* ----------------------------------- */
    /*        Data Fetching Logic          */
    /* ----------------------------------- */

    useEffect(() => {
        if (accounts && debouncedStartDate && debouncedEndDate) {
            // Skip the first automatic fetch if it's not from user interaction
            if (isInitialLoad.current) {
                isInitialLoad.current = false;
                return;
            }
            fetchData(debouncedStartDate, debouncedEndDate);
        }
    }, [accounts, debouncedStartDate, debouncedEndDate, fetchData]);

    // Third - Combine regular and yearly data fetch
    useEffect(() => {
        if (isInitialLoad.current && accounts) {
            const handler = setTimeout(() => {
                const filters = document.getElementById('dateRange');
                if (filters) {
                    const event = new Event('change', { bubbles: true });
                    filters.dispatchEvent(event);
                }
            }, 0);
            return () => clearTimeout(handler);
        }
    }, [accounts]);

    /* ----------------------------------- */
    /*      Section Rendering Logic        */
    /* ----------------------------------- */
    const toggleStates = Object.fromEntries(
        dataTypes.map(({ key }) => [key, useToggle(true)])
    );

    // Then modify the combinedGBPLocationData function
    const combineGBPLocationData = (locationDataArray) => {
        // Early validation of input array
        if (!Array.isArray(locationDataArray) || locationDataArray.length === 0) {
            return [];
        }

        // Filter out null/undefined entries and validate data structure
        const validLocationData = locationDataArray.filter(location => {
            return location?.data?.[0]?.dailyMetricTimeSeries?.length > 0;
        });

        if (validLocationData.length === 0) {
            return [];
        }

        // Get metrics from the first valid location
        const metrics = validLocationData[0].data[0].dailyMetricTimeSeries
            .map(m => m.dailyMetric)
            .filter(Boolean);

        if (metrics.length === 0) {
            return [];
        }

        const metricsByDate = new Map();

        // Process each location's data
        validLocationData.forEach(location => {
            const locationMetrics = location.data?.[0]?.dailyMetricTimeSeries;
            if (!Array.isArray(locationMetrics)) return;

            locationMetrics.forEach(metricData => {
                if (!metricData?.dailyMetric || !metricData?.timeSeries?.datedValues) return;

                const metricName = metricData.dailyMetric;
                if (!metrics.includes(metricName)) return;

                metricData.timeSeries.datedValues.forEach(dateValue => {
                    // Validate date and value exist and are properly formatted
                    if (!dateValue?.date?.year || !dateValue?.date?.month || !dateValue?.date?.day ||
                        typeof dateValue.value === 'undefined') return;

                    const dateKey = `${dateValue.date.year}-${String(dateValue.date.month).padStart(2, '0')}-${String(dateValue.date.day).padStart(2, '0')}`;

                    if (!metricsByDate.has(dateKey)) {
                        // Initialize all metrics for this date
                        const dateMetrics = {};
                        metrics.forEach(metric => {
                            dateMetrics[metric] = 0;
                        });
                        metricsByDate.set(dateKey, dateMetrics);
                    }

                    const dateMetrics = metricsByDate.get(dateKey);
                    const valueToAdd = parseInt(dateValue.value || '0', 10);
                    if (!isNaN(valueToAdd)) {
                        dateMetrics[metricName] = (dateMetrics[metricName] || 0) + valueToAdd;
                    }
                });
            });
        });

        // If no valid data was collected, return empty array
        if (metricsByDate.size === 0) {
            return [];
        }

        // Convert the aggregated data to the expected format
        return [{
            dailyMetricTimeSeries: metrics.map(metric => ({
                dailyMetric: metric,
                timeSeries: {
                    datedValues: Array.from(metricsByDate.entries())
                        .map(([dateStr, values]) => {
                            const [year, month, day] = dateStr.split('-').map(Number);
                            return {
                                date: { year, month, day },
                                value: String(values[metric] || '0')
                            };
                        })
                        .sort((a, b) => {
                            const dateA = new Date(a.date.year, a.date.month - 1, a.date.day);
                            const dateB = new Date(b.date.year, b.date.month - 1, b.date.day);
                            return dateA - dateB;
                        })
                }
            }))
        }];
    };

    const renderDataSection = ({ key, name, icon, component: Component, getTitle, isOrganic }) => {
        const [isExpanded, toggleExpand] = toggleStates[key];

        const componentRefs = sectionRefs[key]?.refs || {
            default: {
                ref: sectionRefs[key]?.ref,
                label: sectionRefs[key]?.label || name,
                useInPDF: sectionRefs[key]?.useInPDF || false
            }
        };

        if (!accounts) {
            return <LoadingSpinner />;
        }

        const accountsForKey = key === 'reviews'
            ? (accounts?.gbp || [])  // Provide empty array fallback
            : (accounts?.[key] || []); // Provide empty array fallback

        if (!accountsForKey || accountsForKey.length === 0) {
            return null;
        }

        const accountsWithData = accountsForKey.filter(id => {
            if (!data) return false;

            if (key === 'ga4-organic') {
                // For ga4-organic, look at the GA4 data
                const accountData = data?.['ga4']?.[id];
                return accountData && Object.keys(accountData).length > 0;
            }

            // For all other keys, use normal check
            const accountData = data?.[key]?.[id];
            return accountData && Object.keys(accountData).length > 0;
        });

        if (accountsWithData.length === 0) {
            return null;
        }

        const showAllLocationsTab = key === 'gbp' && accountsWithData.length > 1;

        const allLocationsData = showAllLocationsTab ? {
            current: combineGBPLocationData(
                accountsWithData.map(id => ({
                    id,
                    data: data[key]?.[id]
                }))
            ),
            yearOverYear: combineGBPLocationData(
                accountsWithData.map(id => ({
                    id,
                    data: yearOverYearData?.[key]?.[id]
                }))
            ),
            previousPeriod: combineGBPLocationData(
                accountsWithData.map(id => ({
                    id,
                    data: previousPeriodData?.[key]?.[id]
                }))
            ),
            customPeriod: combineGBPLocationData(
                accountsWithData.map(id => ({
                    id,
                    data: customPeriodData?.[key]?.[id]
                }))
            )
        } : null;

        return (
            <div key={key} className='rr-statcard'>
                <StatcardHeader
                    title={name}
                    connection={true}
                    icon={icon}
                    accountName={accounts?.name}
                    isExpanded={isExpanded}
                    toggleExpand={toggleExpand}
                    startDate={prettyDate(startDate)}
                    endDate={prettyDate(endDate)}
                />
                <div className={`expandable-content ${isExpanded ? 'expandable-content--expanded' : 'expandable-content--collapsed'}`}>
                    {accountsWithData.length > 1 ? (
                        <Tab.Group onChange={(index) => setActiveTabIndex((prev) => ({ ...prev, [key]: index }))}>
                            <div className={`rr-account-tabs-wrapper ${(accountsWithData.length + (showAllLocationsTab ? 1 : 0)) > 3 ? 'slider' : ''}`}>
                                {(accountsWithData.length + (showAllLocationsTab ? 1 : 0)) > 3 && (
                                    <button onClick={() => scrollTabs(key, -1)} className='tab-arrow left' disabled={activeTabIndex[key] === 0}>
                                        <ChevronLeftIcon className='h-5 w-5' />
                                    </button>
                                )}
                                <Tab.List className='rr-account-tabs' ref={(el) => (tabsRef.current[key] = el)}>
                                    {showAllLocationsTab && (
                                        <Tab className={({ selected }) => `rr-tab-single ${selected ? 'tab-active' : 'tab-inactive'}`}>
                                            All Locations
                                        </Tab>
                                    )}
                                    {accountsWithData.map((id) => (
                                        <Tab key={id} className={({ selected }) => `rr-tab-single ${selected ? 'tab-active' : 'tab-inactive'}`}>
                                            {key === 'call-tracking'
                                                ? getTitle(id, accounts, data?.[key]?.[id])  // Add optional chaining
                                                : getTitle(id, accounts)}
                                        </Tab>
                                    ))}
                                </Tab.List>
                                {(accountsWithData.length + (showAllLocationsTab ? 1 : 0)) > 3 && (
                                    <button onClick={() => scrollTabs(key, 1)} className='tab-arrow right' disabled={activeTabIndex[key] === accountsWithData.length}>
                                        <ChevronRightIcon className='h-5 w-5' />
                                    </button>
                                )}
                            </div>
                            <Tab.Panels>
                                {showAllLocationsTab && (
                                    <Tab.Panel className='rr-statcard-cards'>
                                        <ErrorBoundary>
                                            <Suspense fallback={<LoadingSpinner />}>
                                                <Component
                                                    data={allLocationsData.current}
                                                    yearOverYearData={allLocationsData.yearOverYear}
                                                    previousPeriodData={allLocationsData.previousPeriod}
                                                    customPeriodData={allLocationsData.customPeriod}
                                                    loadingState={loadingStates[key]}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    allLocations={true}
                                                    locationData={accountsWithData.map(id => ({
                                                        name: getTitle(id, accounts),
                                                        data: data[key]?.[id],
                                                        yearOverYearData: yearOverYearData[key]?.[id],
                                                        previousPeriodData: previousPeriodData[key]?.[id],
                                                        customPeriodData: customPeriodData[key]?.[id]
                                                    }))}
                                                />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </Tab.Panel>
                                )}
                                {accountsWithData.map((id) => (
                                    <Tab.Panel key={id} className='rr-statcard-cards'>
                                        {errorStates[key]?.[id] ? (
                                            <div className='text-red-600'>
                                                <ExclamationCircleIcon className='h-5 w-5 inline-block mr-2' />
                                                Error: {errorStates[key][id]}
                                            </div>
                                        ) : loadingStates[key] ? (
                                            <LoadingSpinner />
                                        ) : (
                                            <ErrorBoundary>
                                                <Suspense fallback={<LoadingSpinner />}>
                                                    <Component
                                                        data={data?.[key]?.[id] || {}}
                                                        yearOverYearData={yearOverYearData?.[key]?.[id] || {}}
                                                        previousPeriodData={previousPeriodData?.[key]?.[id] || {}}
                                                        customPeriodData={customPeriodData?.[key]?.[id] || {}}
                                                        loadingState={loadingStates[key]}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        pdfRefs={componentRefs}
                                                        isPDFLayout={false}
                                                        siteUrl={accountsWithData[0]}
                                                        isOrganic={isOrganic}
                                                        gbpLocationName={getTitle(id, accounts)}
                                                    />
                                                </Suspense>
                                            </ErrorBoundary>
                                        )}
                                    </Tab.Panel>
                                ))}
                            </Tab.Panels>
                        </Tab.Group>
                    ) : (
                        <div className='rr-statcard-cards'>
                            <ErrorBoundary>
                                <Suspense fallback={<LoadingSpinner />}>
                                    <Component
                                        data={data[key]?.[accountsWithData[0]] || {}}
                                        yearOverYearData={yearOverYearData?.[key]?.[accountsWithData[0]] || {}}
                                        previousPeriodData={previousPeriodData?.[key]?.[accountsWithData[0]] || {}}
                                        customPeriodData={customPeriodData?.[key]?.[accountsWithData[0]] || {}}
                                        loadingState={loadingStates[key]}
                                        startDate={startDate}
                                        endDate={endDate}
                                        pdfRefs={componentRefs}
                                        isPDFLayout={false}
                                        siteUrl={accountsWithData[0]}
                                        isOrganic={isOrganic}
                                    />
                                </Suspense>
                            </ErrorBoundary>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    /* ----------------------------------- */
    /*         Admin Access Control        */
    /* ----------------------------------- */
    if (window.location.pathname.includes('/admin/') && !isAdmin) {
        return (
            <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100'>
                <h1 className='text-2xl font-bold text-red-600 mb-4'>Access Denied</h1>
                <p className='text-gray-700'>
                    You do not have admin privileges to view this page.
                </p>
            </div>
        );
    }

    /* ----------------------------------- */
    /*         Dashboard Rendering         */
    /* ----------------------------------- */
    return (
        <div className={`theme-${theme}`}>
            {isAdmin && (
                <div className={`dashboard-admin-nav ${isAdminNavOpen ? 'open' : ''}`}>
                    <AdminNavbar
                        theme={theme}
                        onClose={() => setIsAdminNavOpen(false)}
                    />
                </div>
            )}
            <DashboardNavigation
                theme={theme}
                dataTypes={dataTypes}
                validDataTypes={validDataTypes}
                accounts={accounts}
                startDate={startDate}
                endDate={endDate}
                setStartDate={(date) => setDateRange(date, endDate)}
                setEndDate={(date) => setDateRange(startDate, date)}
                onDateChange={(newStartDate, newEndDate) => setDateRange(newStartDate, newEndDate)}
                admin={isAdmin}
                setIsAdminNavOpen={setIsAdminNavOpen}
            />

            <div className='rr-dashboard-wrapper' ref={sectionRefs.dashboardTop.ref} id='dashboard-top'>
                <div className='rr-dashboard'>
                    <div className='max-w-full mx-auto'>
                        <div className='rr-dashboard-body'>
                            {/* Don't show Snapshot or Leadgen if e-commerce data exists */}
                            {!sourcesWithData.ecommerce && (
                                <>
                                    <ErrorBoundary>
                                        <Suspense fallback={<div>Loading Snapshot...</div>}>
                                            {visibleComponents.snapshot && (
                                                <div className='rr-dashboard-topstats'>
                                                    <Snapshot
                                                        data={data || {}}
                                                        yearOverYearData={yearOverYearData || {}}
                                                        previousPeriodData={previousPeriodData || {}}
                                                        customPeriodData={customPeriodData || {}}
                                                        loadingStates={loadingStates}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        previousStartDate={previousStartDate}
                                                        previousEndDate={previousEndDate}
                                                        accountName={accounts?.name}
                                                        pdfRef={sectionRefs.dashboardSnapshotCurrent.ref}
                                                    />
                                                </div>
                                            )}
                                        </Suspense>
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <Suspense fallback={<div>Loading Lead Generation...</div>}>
                                            {visibleComponents.leadgen && (
                                                <div className='rr-dashboard-topstats'>
                                                    <LeadGeneration
                                                        data={data || {}}
                                                        yearOverYearData={yearOverYearData || {}}
                                                        previousPeriodData={previousPeriodData || {}}
                                                        customPeriodData={customPeriodData || {}}
                                                        loadingStates={loadingStates}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        previousStartDate={previousStartDate}
                                                        previousEndDate={previousEndDate}
                                                        accountName={accounts?.name}
                                                        pdfRef={sectionRefs.dashboardLeadgen.ref}
                                                    />
                                                </div>
                                            )}
                                        </Suspense>
                                    </ErrorBoundary>
                                </>
                            )}

                            <div>
                                {isDataLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    dataTypes.map((dataType) => {
                                        return (
                                            visibleComponents[dataType.key] &&
                                            sourcesWithData[dataType.key] && (
                                                <React.Fragment key={dataType.key}>
                                                    <div className='print-section'>
                                                        {renderDataSection(dataType)}
                                                    </div>
                                                </React.Fragment>
                                            )
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <Suspense fallback={null}>
                <LoadingOverlay />
            </Suspense>
        </div>
    );
}

export default Dashboard;
