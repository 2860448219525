// useDebouncedDateRange.js - simplified version
import { useState, useEffect, useCallback, useRef } from 'react';
import { normalizeDate } from '../utils/dataProcessing';

export const useDebouncedDateRange = (initialStartDate, initialEndDate, delay = 500) => {
    // Ensure initial dates are normalized once at startup
    const [dateRange, setDateRange] = useState({
        start: normalizeDate(initialStartDate),
        end: normalizeDate(initialEndDate)
    });

    const [debouncedRange, setDebouncedRange] = useState({
        start: normalizeDate(initialStartDate),
        end: normalizeDate(initialEndDate)
    });

    const updateDateRange = useCallback((newStart, newEnd) => {
        const normalizedStart = normalizeDate(newStart);
        const normalizedEnd = normalizeDate(newEnd);
        setDateRange({ start: normalizedStart, end: normalizedEnd });
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedRange(dateRange);
        }, delay);

        return () => clearTimeout(handler);
    }, [dateRange, delay]);

    return {
        startDate: dateRange.start,
        endDate: dateRange.end,
        debouncedStartDate: debouncedRange.start,
        debouncedEndDate: debouncedRange.end,
        setDateRange: updateDateRange
    };
};
