// LoadingOverlay.jsx
import { useEffect, useRef } from 'react';
import { useGlobalData } from '../../contexts/GlobalDataContext';

const LoadingOverlay = () => {
    const { loadingStates } = useGlobalData();
    const hasStartedLoading = useRef(false);
    
    // Check if ANY data type is loading
    const isLoading = Object.values(loadingStates).some(state => state === true);

    useEffect(() => {
        if (isLoading) {
            hasStartedLoading.current = true;
            document.body.style.overflow = 'hidden';
        } else if (hasStartedLoading.current) {
            setTimeout(() => {
                hasStartedLoading.current = false;
                document.body.style.overflow = 'unset';
            }, 500);
        }
    }, [isLoading]);

    if (!isLoading) return null;

    return (
        <div className="loading-overlay opacity-100">
            <div className="loading-overlay-box">
                <h2 className="loading-overlay-title">Building Your Report...</h2>
                <div className="loading-overlay-element">
                    <svg className="loading-overlay-loader" viewBox="0 0 24 24">
                        <circle cx="12" cy="12" r="10" className="opacity-25" stroke="currentColor" strokeWidth="4" fill="none" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" />
                    </svg>
                    <span className="loading-overlay-item">Loading Data...</span>
                </div>
            </div>
        </div>
    );
};

export default LoadingOverlay;
