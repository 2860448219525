// ProtectedRoute.jsx
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
    const { token, isAdmin, loading } = useAuth();
    const location = useLocation();
    const isAdminRoute = location.pathname.includes('/admin/');

    if (loading) {
        return null;
    }

    if (isAdminRoute && !isAdmin) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export default ProtectedRoute;
