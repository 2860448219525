import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTheme } from './AuthContext';
import axiosDataPipeline from "../api/axiosDataPipeline";
import GradientBg from './GradientBg';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const theme = useTheme();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosDataPipeline.post("/user/forgot-password", {
                email,
            });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.message || "An error occurred");
        }
    };

    const handleBackToLogin = () => {
        navigate('/login');
    };

    return (
        <div className={`theme-${theme}`}>
            <GradientBg theme={theme} />
            <div className='rr-login'>
                <div className='login-wrapper-main'>
                    <div className='rr-login-col'>
                        <div className='rr-login-logo'>
                            {theme === 'mta' ? (
                                <img
                                    src='/mta-logo.png'
                                    alt='mta360 logo'
                                />
                            ) : (
                                <img
                                    src='/range-pin.png'
                                    alt='Range Marketing logo'
                                />
                            )}
                        </div>
                        <h2 className='mb-6 text-center text-3xl font-extrabold text-white'>
                            Forgot Password
                        </h2>
                        <div>
                            <form className='space-y-6' onSubmit={handleSubmit}>
                                <div className='form-group'>
                                    <label
                                        htmlFor='email'
                                        className='block text-sm font-medium text-gray-700'
                                    >
                                        Email
                                    </label>
                                    <div className='mt-1'>
                                        <input
                                            id='email'
                                            name='email'
                                            type='email'
                                            required
                                            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Enter your email"
                                        />
                                    </div>
                                </div>

                                {message && <div className='text-green-600 text-sm'>{message}</div>}

                                <div>
                                    <button
                                        type='submit'
                                        className={`btn ${theme === 'mta' ? 'btn-yellow' : 'btn-green'} btn-lg w-full`}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            </form>
                            <div className='mt-3'>
                                <button
                                    onClick={handleBackToLogin}
                                    className={`${theme === 'mta' ? 'text-yellow-400 hover:text-yellow-600' : 'text-blue-600 hover:text-blue-400'} mt-3`}
                                >
                                    Back to Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
