import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosDataPipeline from "../api/axiosDataPipeline";
import { useTheme } from "./AuthContext";
import GradientBg from "./GradientBg";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const { token } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }
    try {
      const response = await axiosDataPipeline.post(
        `/user/reset-password/${token}`,
        { password },
      );
      setMessage(response.data.message);
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      setMessage(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <div className={`theme-${theme}`}>
      <GradientBg theme={theme} />
      <div className="rr-login">
        <div className="login-wrapper-main">
          <div className="rr-login-col">
            <div className="rr-login-logo">
              {theme === "mta" ? (
                <img src="/mta-logo.png" alt="mta360 logo" />
              ) : (
                <img src="/range-pin.png" alt="Range Marketing logo" />
              )}
            </div>
            <h2 className="mb-6 text-center text-3xl font-extrabold text-white">
              Reset Password
            </h2>
            <div>
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    New Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="New password"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Confirm New Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm new password"
                    />
                  </div>
                </div>

                {message && (
                  <div className="text-red-600 text-sm">{message}</div>
                )}

                <div>
                  <button
                    type="submit"
                    className={`btn ${theme === "mta" ? "btn-yellow" : "btn-green"} btn-lg w-full`}
                  >
                    Set New Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
