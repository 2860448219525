import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition, Disclosure } from "@headlessui/react";
import {
    Bars3Icon,
    ArrowDownOnSquareIcon,
    HomeIcon,
    XMarkIcon,
    ChevronRightIcon,
    UserPlusIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import { classNames } from "../../Utilities";
import axiosDataPipeline from "../../api/axiosDataPipeline";

const Navbar = ({ theme }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { token, logout } = useAuth();
    const [isAdmin, setIsAdmin] = useState(false);

    const [navigation, setNavigation] = useState([
        { name: "Dashboard", href: "/", icon: HomeIcon, current: true },
    ]);

    useEffect(() => {
        const checkAdminStatus = async () => {
            if (token) {
                try {
                    const response = await axiosDataPipeline.get("/user/permissions");
                    setIsAdmin(response.data.admin);
                } catch (error) {
                    console.error("Error checking admin status:", error);
                }
            }
        };
        checkAdminStatus();
    }, [token]);

    useEffect(() => {
        if (isAdmin) {
            setNavigation((prevNavigation) => [
                ...prevNavigation,
                { name: "Admin", href: "/admin", icon: UserPlusIcon, current: false },
            ]);
        } else {
            setNavigation((prevNavigation) =>
                prevNavigation.filter((item) => item.name !== "Admin"),
            );
        }
    }, [isAdmin]);

    const handleLogout = (e) => {
        e.preventDefault();
        logout();
    };

    const renderNavItem = (item) => {
        if (!item.children) {
            return (
                <NavLink
                    to={item.href}
                    className={({ isActive }) =>
                        classNames(
                            isActive ? "rr-navbar-item-active" : "rr-navbar-item-inactive",
                            "group rr-navbar-item",
                        )
                    }
                >
                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    {item.name}
                </NavLink>
            );
        }

        return (
            <Disclosure as="div" className="space-y-1">
                {({ open }) => (
                    <>
                        <Disclosure.Button
                            className={classNames(
                                item.current
                                    ? "bg-gray-50 text-blue-600"
                                    : "text-gray-700 hover:text-blue-600 hover:bg-gray-50",
                                "group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm leading-6 font-semibold",
                            )}
                        >
                            <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                            {item.name}
                            <ChevronRightIcon
                                className={classNames(
                                    open ? "rotate-90 text-gray-500" : "text-gray-400",
                                    "ml-auto h-5 w-5 shrink-0",
                                )}
                                aria-hidden="true"
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="space-y-1 px-4">
                            {item.children.map((subItem) => (
                                <NavLink
                                    key={subItem.name}
                                    to={subItem.href}
                                    className={({ isActive }) =>
                                        classNames(
                                            isActive
                                                ? "bg-gray-50 text-blue-600"
                                                : "text-gray-700 hover:text-blue-600 hover:bg-gray-50",
                                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold pl-9",
                                        )
                                    }
                                >
                                    <subItem.icon
                                        className="h-6 w-6 shrink-0"
                                        aria-hidden="true"
                                    />
                                    {subItem.name}
                                </NavLink>
                            ))}
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        );
    };

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50 lg:hidden"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                                    <div className="flex h-16 shrink-0 items-center">
                                        {theme == "mta" ? (
                                            <img
                                                className="h-8 w-auto"
                                                src="/mta-logo.png"
                                                alt="mta360 logo"
                                            />
                                        ) : (
                                            <img
                                                className="h-8 w-auto"
                                                src="/range-pin.png"
                                                alt="Range Marketing logo"
                                            />
                                        )}
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul role="list" className="-mx-2 space-y-1">
                                                    {navigation.map((item) => (
                                                        <li key={item.name}>
                                                            <NavLink
                                                                to={item.href}
                                                                className={({ isActive }) =>
                                                                    classNames(
                                                                        isActive
                                                                            ? "bg-gray-50 text-blue-600"
                                                                            : "text-gray-700 hover:text-blue-600 hover:bg-gray-50",
                                                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                                                                    )
                                                                }
                                                            >
                                                                <item.icon
                                                                    className="h-6 w-6 shrink-0"
                                                                    aria-hidden="true"
                                                                />
                                                                {item.name}
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="rr-navbar-wrapper">
                <div className="rr-navbar">
                    <div className="flex my-5 shrink-0 items-center">
                        {theme == "mta" ? (
                            <img
                                className="h-20 w-auto"
                                src="/mta-logo.png"
                                alt="mta360 logo"
                            />
                        ) : (
                            <img
                                className="h-14 pt-3 w-auto"
                                src="/range-pin.png"
                                alt="Range Marketing logo"
                            />
                        )}
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul role="list" className="-mx-2 space-y-1">
                                    {navigation.map((item) => (
                                        <li key={item.name}>{renderNavItem(item)}</li>
                                    ))}
                                    <li>
                                        <NavLink
                                            onClick={handleLogout}
                                            className="rr-navbar-item-inactive group rr-navbar-item rr-navbar-logout"
                                        >
                                            <ArrowDownOnSquareIcon
                                                className="h-6 w-6 shrink-0"
                                                aria-hidden="true"
                                            />
                                            Log out
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                <button
                    type="button"
                    className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
                    Dashboard
                </div>
            </div>
        </>
    );
};

export default Navbar;
