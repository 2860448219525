import React from 'react';

const GradientBg = ({ overlay, theme }) => {
    return (
        <div className='animated-bg'>
            {overlay && (
                <div className='animated-bg-overlay' />
            )}
            {theme == 'mta' ? (
                <svg viewBox='0 0 100 100' preserveAspectRatio='xMidYMid slice'>
                    <defs>
                        <radialGradient id='Gradient1' cx='50%' cy='50%' fx='0.441602%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='102s' values='0%;3%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(38, 189, 120, 1)'></stop>
                            <stop offset='100%' stopColor='rgba(38, 189, 120, 0)'></stop>
                        </radialGradient>
                        <radialGradient id='Gradient2' cx='50%' cy='50%' fx='2.68147%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='70.5s' values='0%;3%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(2, 62, 35, 1)'></stop>
                            <stop offset='100%' stopColor='rgba(2, 62, 35, 0)'></stop>
                        </radialGradient>
                        <radialGradient id='Gradient3' cx='50%' cy='50%' fx='0.836536%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='64.5s' values='0%;3%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(231, 186, 5, 1)'></stop>
                            <stop offset='100%' stopColor='rgba(231, 186, 5, 0)'></stop>
                        </radialGradient>
                        <radialGradient id='Gradient4' cx='50%' cy='50%' fx='4.56417%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='69s' values='0%;5%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(19, 94, 60, 1)'></stop>
                            <stop offset='100%' stopColor='rgba(19, 94, 60, 0)'></stop>
                        </radialGradient>
                        <radialGradient id='Gradient5' cx='50%' cy='50%' fx='2.65405%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='74.5s' values='0%;5%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(1, 31, 17, 1)'></stop>
                            <stop offset='100%' stopColor='rgba(1, 31, 17, 0)'></stop>
                        </radialGradient>
                        <radialGradient id='Gradient6' cx='50%' cy='50%' fx='0.981338%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='75.5s' values='0%;5%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(115, 93, 2, 0.8)'></stop>
                            <stop offset='100%' stopColor='rgba(115, 93, 2, 0)'></stop>
                        </radialGradient>
                    </defs>
                    <rect x='13.744%' y='1.18473%' width='100%' height='100%' fill='url(#Gradient1)' transform='rotate(334.41 50 50)'>
                        <animate attributeName='x' dur='20s' values='25%;0%;25%' repeatCount='indefinite'></animate>
                        <animate attributeName='y' dur='21s' values='0%;25%;0%' repeatCount='indefinite'></animate>
                        <animateTransform attributeName='transform' type='rotate' from='0 50 50' to='360 50 50' dur='21s' repeatCount='indefinite'></animateTransform>
                    </rect>
                    <rect x='-2.17916%' y='35.4267%' width='100%' height='100%' fill='url(#Gradient2)' transform='rotate(255.072 50 50)'>
                        <animate attributeName='x' dur='23s' values='-25%;0%;-25%' repeatCount='indefinite'></animate>
                        <animate attributeName='y' dur='24s' values='0%;50%;0%' repeatCount='indefinite'></animate>
                        <animateTransform attributeName='transform' type='rotate' from='0 50 50' to='360 50 50' dur='36s' repeatCount='indefinite'></animateTransform>
                    </rect>
                    <rect x='9.00483%' y='14.5733%' width='100%' height='100%' fill='url(#Gradient3)' transform='rotate(139.903 50 50)'>
                        <animate attributeName='x' dur='25s' values='0%;25%;0%' repeatCount='indefinite'></animate>
                        <animate attributeName='y' dur='12s' values='0%;25%;0%' repeatCount='indefinite'></animate>
                        <animateTransform attributeName='transform' type='rotate' from='360 50 50' to='0 50 50' dur='27s' repeatCount='indefinite'></animateTransform>
                    </rect>
                </svg>
            ) : (
                <svg viewBox='0 0 100 100' preserveAspectRatio='xMidYMid slice'>
                    <defs>
                        <radialGradient id='Gradient1' cx='50%' cy='50%' fx='0.441602%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='102s' values='0%;3%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(38, 118, 248, 1)'></stop>
                            <stop offset='100%' stopColor='rgba(38, 118, 248, 0)'></stop>
                        </radialGradient>
                        <radialGradient id='Gradient2' cx='50%' cy='50%' fx='2.68147%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='70.5s' values='0%;3%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(19, 59, 124, 1)'></stop>
                            <stop offset='100%' stopColor='rgba(19, 59, 124, 0)'></stop>
                        </radialGradient>
                        <radialGradient id='Gradient3' cx='50%' cy='50%' fx='0.836536%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='64.5s' values='0%;3%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(3, 13, 30, 1)'></stop>
                            <stop offset='100%' stopColor='rgba(3, 13, 30, 0)'></stop>
                        </radialGradient>
                        <radialGradient id='Gradient4' cx='50%' cy='50%' fx='4.56417%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='69s' values='0%;5%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(57, 136, 255, 1)'></stop>
                            <stop offset='100%' stopColor='rgba(57, 136, 255, 0)'></stop>
                        </radialGradient>
                        <radialGradient id='Gradient5' cx='50%' cy='50%' fx='2.65405%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='74.5s' values='0%;5%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(11, 36, 71, 1)'></stop>
                            <stop offset='100%' stopColor='rgba(11, 36, 71, 0)'></stop>
                        </radialGradient>
                        <radialGradient id='Gradient6' cx='50%' cy='50%' fx='0.981338%' fy='50%' r='.5'>
                            <animate attributeName='fx' dur='75.5s' values='0%;5%;0%' repeatCount='indefinite'></animate>
                            <stop offset='0%' stopColor='rgba(38, 118, 248, 0.8)'></stop>
                            <stop offset='100%' stopColor='rgba(38, 118, 248, 0)'></stop>
                        </radialGradient>
                    </defs>
                    <rect x='13.744%' y='1.18473%' width='100%' height='100%' fill='url(#Gradient1)' transform='rotate(334.41 50 50)'>
                        <animate attributeName='x' dur='20s' values='25%;0%;25%' repeatCount='indefinite'></animate>
                        <animate attributeName='y' dur='21s' values='0%;25%;0%' repeatCount='indefinite'></animate>
                        <animateTransform attributeName='transform' type='rotate' from='0 50 50' to='360 50 50' dur='21s' repeatCount='indefinite'></animateTransform>
                    </rect>
                    <rect x='-2.17916%' y='35.4267%' width='100%' height='100%' fill='url(#Gradient2)' transform='rotate(255.072 50 50)'>
                        <animate attributeName='x' dur='23s' values='-25%;0%;-25%' repeatCount='indefinite'></animate>
                        <animate attributeName='y' dur='24s' values='0%;50%;0%' repeatCount='indefinite'></animate>
                        <animateTransform attributeName='transform' type='rotate' from='0 50 50' to='360 50 50' dur='36s' repeatCount='indefinite'></animateTransform>
                    </rect>
                    <rect x='9.00483%' y='14.5733%' width='100%' height='100%' fill='url(#Gradient3)' transform='rotate(139.903 50 50)'>
                        <animate attributeName='x' dur='25s' values='0%;25%;0%' repeatCount='indefinite'></animate>
                        <animate attributeName='y' dur='12s' values='0%;25%;0%' repeatCount='indefinite'></animate>
                        <animateTransform attributeName='transform' type='rotate' from='360 50 50' to='0 50 50' dur='27s' repeatCount='indefinite'></animateTransform>
                    </rect>
                </svg>
            )}
        </div>
    );
};

export default GradientBg;
