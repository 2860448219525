import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth, useTheme } from '../auth/AuthContext';
import axiosDataPipeline from '../api/axiosDataPipeline';
import Navbar from './Navigation/Navbar';

const Admin = () => {
    const [org, setOrg] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { token, isAdmin } = useAuth();
    const theme = useTheme();

    useEffect(() => {
        async function checkUserPermissions() {
            if (!token) return;
            try {
                const response = await axiosDataPipeline.get('/user/permissions');
                setOrg(response.data.org);
                if (response.data.admin) {
                    const response = await axiosDataPipeline.get('/user/admin');
                    const sortedAccounts = response.data.sort((a, b) =>
                        a.name.localeCompare(b.name),
                    );
                    setAccounts(sortedAccounts);
                    setFilteredAccounts(sortedAccounts);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        }
        checkUserPermissions();
    }, [token]);

    useEffect(() => {
        const filtered = accounts.filter((account) =>
            account.name.toLowerCase().includes(searchTerm.toLowerCase()),
        );
        setFilteredAccounts(filtered);
    }, [searchTerm, accounts]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    if (isLoading) {
        return (
            <div className='loading-container'>
                <div className='loading-content'>
                    <div className='loading-spinner'>
                        <div className='loading-spinner-inner'></div>
                    </div>
                    <h2 className='loading-text'>Loading...</h2>
                    <p className='loading-subtext'>
                        Please wait while we prepare your content.
                    </p>
                </div>
            </div>
        );
    }

    if (!isAdmin) {
        return (
            <div className='access-denied-container'>
                <h1 className='access-denied-title'>Access Denied</h1>
                <p className='access-denied-message'>
                    You do not have admin privileges to view this page.
                </p>
            </div>
        );
    }

    return (
        <div className={`theme-${theme}`}>
            <div className='admin-container'>
                <Navbar theme={theme} />
                <div className='admin-content'>
                    <h1 className='admin-title'>Admin Dashboard</h1>
                    <div className='mb-4'>
                        <input
                            type='text'
                            placeholder='Search accounts by name...'
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className='admin-search'
                        />
                    </div>
                    <div className='admin-table-container'>
                        <table className='admin-table'>
                            <thead className='admin-table-header'>
                                <tr>
                                    <th className='admin-table-header-cell'>ID</th>
                                    <th className='admin-table-header-cell'>Name</th>
                                    <th className='admin-table-header-cell'>Action</th>
                                </tr>
                            </thead>
                            <tbody className='admin-table-body'>
                                {filteredAccounts.map((account) => (
                                    <tr key={account.id} className='admin-table-row'>
                                        <td className='admin-table-cell'>{account.id}</td>
                                        <td className='admin-table-cell admin-table-name'>
                                            <a href={`/admin/${account.id}`} className='text-decoration-none text-dark-900 font-bold'>{account.name}</a>
                                        </td>
                                        <td className='admin-table-cell admin-button-cell'>
                                            <a href={`/admin/${account.id}`} className='btn btn-primary'>View</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Admin;
