/**
 * Toggle Data Sections
 */
import { useState, useCallback } from 'react';

const useToggle = (initialState = true) => {
    const [isExpanded, setIsExpanded] = useState(initialState);

    const toggle = useCallback(() => {
        setIsExpanded(prev => !prev);
    }, []);

    return [isExpanded, toggle];
};

export default useToggle;
