import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useTheme } from './AuthContext';
import axiosDataPipeline from '../api/axiosDataPipeline';
import GradientBg from './GradientBg';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { login } = useAuth();
    const theme = useTheme();
    const navigate = useNavigate();

    // Add input state tracking
    const [focused, setFocused] = useState({
        email: false,
        password: false
    });
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await axiosDataPipeline.post('/user/login', {
                email,
                password,
            });
            if (response.data) {
                login(response.data);
                navigate('/');
            } else {
                setError('Login failed. Please check your credentials.');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('An error occurred during login. Please try again.');
        }
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <div className={`theme-${theme}`}>
            <GradientBg theme={theme} />
            <div className='rr-login'>
                <div className='login-wrapper-main'>
                    <div className='rr-login-col'>
                        <div className='rr-login-logo'>
                            {theme === 'mta' ? (
                                <img src='/mta-logo.png' alt='mta360 logo' />
                            ) : (
                                <img src='/range-pin.png' alt='Range Marketing logo' />
                            )}
                        </div>
                        <h2 className='mb-6 text-center text-3xl font-extrabold text-white'>
                            Sign into your account
                        </h2>
                        <div>
                            <form className='space-y-6' onSubmit={handleSubmit}>
                                <div className='form-group'>
                                    <label
                                        htmlFor='email'
                                        className={email || focused.email ? 'label-up' : ''}
                                    >
                                        Email
                                    </label>
                                    <input
                                        id='email'
                                        name='email'
                                        type='email'
                                        required
                                        className={email || focused.email ? 'input-up' : ''}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onFocus={() => setFocused(prev => ({ ...prev, email: true }))}
                                        onBlur={() => setFocused(prev => ({ ...prev, email: false }))}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label
                                        htmlFor='password'
                                        className={password || focused.password ? 'label-up' : ''}
                                    >
                                        Password
                                    </label>
                                    <input
                                        id='password'
                                        name='password'
                                        type='password'
                                        required
                                        className={password || focused.password ? 'input-up' : ''}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onFocus={() => setFocused(prev => ({ ...prev, password: true }))}
                                        onBlur={() => setFocused(prev => ({ ...prev, password: false }))}
                                    />
                                </div>

                                {error && <div className='text-red-600 text-sm'>{error}</div>}

                                <div>
                                    <button
                                        type='submit'
                                        className={`btn ${theme === 'mta' ? 'btn-yellow' : 'btn-green'} btn-lg w-full`}
                                    >
                                        Sign in
                                    </button>
                                </div>
                            </form>
                            <div className='mt-3'>
                                <button
                                    onClick={handleForgotPassword}
                                    className={`${theme === 'mta' ? 'text-yellow-400 hover:text-yellow-600' : 'text-blue-600 hover:text-blue-400'} mt-3`}
                                >
                                    Forgot password?
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
